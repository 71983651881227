<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-21.mp3"),
      },
      timeStampList: [8.6, 12.8, 17.0, 21.5, 24.0, 27.0],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Nǐ jǐ suì? Nǐ duō dà?",
            chineseWords: "你几岁？你多大？",
          },
          {
            id: 2,
            spelling: "Wǒ sì suì.",
            chineseWords: "我四岁。",
          },
          {
            id: 3,
            spelling: "Nǐ gēge jǐ suì? Nǐ gēge duō dà?",
            chineseWords: "你哥哥几岁？你哥哥多大？",
          },
          {
            id: 4,
            spelling: "Wǒ gēge liù suì.",
            chineseWords: "我哥哥六岁。",
          },
          {
            id: 5,
            spelling: "Wǒ yě liù suì.",
            chineseWords: "我也六岁。",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
