<template>
  <div class="page-container">
    <SpeakingSentence 
     :buttonList="buttonList"
     :gameList="gameList"
     :isClick="false"
     :lesson="21"
     />
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      buttonList: [
      {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-21/menu-bday-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-21/menu-bday-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-21/menu-bday-3.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-21/menu-bday-4.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
      ],
      gameList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-21/image-1.svg"),
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-21/image-2.svg"),
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-21/image-3.svg"),
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-21/image-4.svg"),
        }
      ],
    };
  },
  components: {
    SpeakingSentence,
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  width: 100%;
  height: 100%;
}
</style>
