<template>
  <div class="game-container">
    <DragToCakeGame
      :bgImgList="bgImgList"
      :bgImg="bgImg"
      :optionImgList="optionImgList"
      :lessonNO="21"
      :hasStar="false"
    />
  </div>
</template>

<script>
import DragToCakeGame from "@/components/Course/GamePage/DragToCakeGame";
export default {
  data() {
    return {
      dragCurImg: "",
      bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/background-cake.svg"),
      bgImgList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/1-done.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/2-done.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/3-done.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/4-done.svg"),
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/5-done.svg"),
        },
        {
          id: 6,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/6-done.svg"),
        },
        {
          id: 7,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/7-done.svg"),
        },
        {
          id: 8,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/8-done.svg"),
        },
        {
          id: 9,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/9-done.svg"),
        },
        {
          id: 10,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/0-done.svg"),
        },
      ],
      optionImgList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/1.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/2.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/3.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/4.svg"),
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/5.svg"),
        },
        {
          id: 6,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/6.svg"),
        },
        {
          id: 7,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/7.svg"),
        },
        {
          id: 8,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/8.svg"),
        },
        {
          id: 9,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/9.svg"),
        },
        {
          id: 10,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-21/0.svg"),
        },
      ],
    };
  },
  components: {
    DragToCakeGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











