import { render, staticRenderFns } from "./16_PracticePage_five.vue?vue&type=template&id=de25e678&scoped=true"
import script from "./16_PracticePage_five.vue?vue&type=script&lang=js"
export * from "./16_PracticePage_five.vue?vue&type=script&lang=js"
import style0 from "./16_PracticePage_five.vue?vue&type=style&index=0&id=de25e678&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de25e678",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/nihaokidsNew-front_BmM9/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('de25e678')) {
      api.createRecord('de25e678', component.options)
    } else {
      api.reload('de25e678', component.options)
    }
    module.hot.accept("./16_PracticePage_five.vue?vue&type=template&id=de25e678&scoped=true", function () {
      api.rerender('de25e678', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Course/Level1/Chapter3/Lesson21/16_PracticePage_five.vue"
export default component.exports