<template>
  <div class="game-container">
    <FindElementByPicGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      :totalStars="totalStars"
      :isNumberGame="true"
    />
    <div class="title-area">
          <div class="title">
            <div class="title-pinyin pinyin font-pinyin-medium">Jǐ?</div>
            <div class="title-hanzi font-hanzi-medium">几?</div>
          </div>
        </div>
  </div>
  
</template>

<script>
import FindElementByPicGame from "@/components/Course/GamePage/FindElementByPicGame";
export default {
  data() {
    return {
      totalStars: 6,
      questionInfoList: [
        {
          id: 5,
          bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/lesson-21/lesson-21-image-3.svg'),
          sound:require('@/assets/audio/L1/5-Words/5.mp3'),
          
        },
        {
          id: 8,
          bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/lesson-21/lesson-21-image-4.svg'),
          sound:require('@/assets/audio/L1/5-Words/8.mp3'),
          
        },
        {
          id: 2,
          bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/lesson-21/lesson-21-image-5.svg'),
          sound:require('@/assets/audio/L1/5-Words/2.mp3'),
          
        },
        {
          id: 6,
          bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/lesson-21/lesson-21-image-2.svg'),
          sound:require('@/assets/audio/L1/5-Words/6.mp3'),
          
        },
        
        {
          id: 9,
          bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/lesson-21/lesson-21-image-6.svg'),
          sound:require('@/assets/audio/L1/5-Words/9.mp3'),
          
        },
        {
          id: 3,
          bgImg: require('@/assets/img/16-GAMES/G30-ten-buttons/lesson-21/lesson-21-image-1.svg'),
          sound:require('@/assets/audio/L1/5-Words/3.mp3'),
          
        },
      ],
      asideElementInfo: {
        leftList: [
          {
          id: 1,
          element: 1,
        },
        {
          id: 2,
          element: 2,
        },
        {
          id: 3,
          element: 3,
        },
        {
          id: 4,
          element: 4,
        },
        {
          id: 5,
          element: 5,
        },
        ],
        rightList: [
          {
          id: 6,
          element: 6,
        },
        {
          id: 7,
          element: 7,
        },
        {
          id: 8,
          element: 8,
        },
        {
          id: 9,
          element: 9,
        },
        {
          id: 10,
          element: 10,
        },
        ],
      },
    };
  },
  components: {
    FindElementByPicGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
  .title-area {
    z-index: 11;
    position: absolute;
    left: 0;
    top: 10%;
    width: 12%;
    height: 22%;
    background: #CD4C3F;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 22px;
    color: #fff;
    .title {
      .title-pinyin,
      .title-hanzi {
        text-align: center;
      }
    }
  }
}
</style>
