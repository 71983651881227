var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("FindElementByPicGame", {
        attrs: {
          asideElementInfo: _vm.asideElementInfo,
          questionInfoList: _vm.questionInfoList,
          totalStars: _vm.totalStars,
          isNumberGame: true,
        },
      }),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-area" }, [
      _c("div", { staticClass: "title" }, [
        _c("div", { staticClass: "title-pinyin pinyin font-pinyin-medium" }, [
          _vm._v("Jǐ?"),
        ]),
        _c("div", { staticClass: "title-hanzi font-hanzi-medium" }, [
          _vm._v("几?"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }