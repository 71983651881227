<template>
  <div class="game-container">
    <ClickImgToShowSentenceGame
      :bgImg="bgImg"
      :optionList="optionList"
      :maskImgList="maskImgList"
      @showCoeerctImg="showCoeerctImg"
      :lessonNO="21"
    />
  </div>
</template>

<script>
import ClickImgToShowSentenceGame from "@/components/Course/GamePage/ClickImgToShowSentenceGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/background-forest.svg"),
      optionList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-crocodile-red.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-crocodile-red.svg"),
          id: 1,
          isShowCorrect: false,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-dolphin-red.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-dolphin-red.svg"),
          id: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-elephant-red.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-elephant-red.svg"),
          id: 3,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-flamingo-red.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-flamingo-red.svg"),
          id: 4,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-giraffe-red.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-giraffe-red.svg"),
          id: 5,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-penguin-red.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-penguin-red.svg"),
          id: 6,
        },
      ],
      maskImgList: [
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-crocodile-story.svg"),
          id: 1,
          centencePinyin: "Wǒ sì suì.",
          sentenceHanzi: "我四岁。",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-dolphin-story.svg"),
          id: 2,
          centencePinyin: "Wǒ sān suì.",
          sentenceHanzi: "我三岁。",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-elephant-story.svg"),
          id: 3,
          centencePinyin: "Wǒ jiǔ suì.",
          sentenceHanzi: "我九岁。",
        },
         {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-flamingo-story.svg"),
          id: 4,
          centencePinyin: "Wǒ bā suì.",
          sentenceHanzi: "我八岁。",
        },
         {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-giraffe-story.svg"),
          id: 5,
          centencePinyin: "Wǒ qī suì.",
          sentenceHanzi: "我七岁。",
        },
         {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-21/image-penguin-story.svg"),
          id: 6,
          centencePinyin: "Wǒ yī suì.",
          sentenceHanzi: "我一岁。",
        },
      ],
    };
  },
  components: {
    ClickImgToShowSentenceGame,
  },
  methods: {
    showCoeerctImg(index) {
      this.optionList[index - 1].isShowCorrect = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











